import { message } from "antd";

export async function httpRequest<T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  body: any = null,
): Promise<{ status: number | 'error'; data: T }> {
  
  let userAgent: any = localStorage.getItem('centerofcode_user')

  if (userAgent != null) {
    userAgent = JSON.parse(userAgent)
  } else {
    userAgent = null
  }
  
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'text/plain',
      'token': userAgent ? userAgent.token : ''
    }
  };

  let query = null

  if (method == 'POST' || method == 'PUT') {
    options.body = JSON.stringify(body)
  } else {
    if (body != null) {
      query = '?' + new URLSearchParams(body).toString()
    }
  }
  
  try {
    const response = await fetch('https://api.trackltup.com:2087/dealer/' + url + (query != null ? query : ''), options)
    const status: any = response.status
    const data = await response.json()

    if (status == '403') {
      message.error(`${data.text} (${status}) WEBSERICES (${url.split('/')[1]})`)
    }

    return { status, data }
  } catch (error: any) {
    console.error('HTTP request failed', error)
    return { status: 'error', data: error.message }
  }
}



// import React, { useEffect, useState } from 'react'

// type ApiResponse = {
//   key: string;
//   value: string;
// }

// const MyComponent: React.FC = () => {
//   const [response, setResponse] = useState<{ status: number | 'error'; data: ApiResponse | null }>({
//     status: 0,
//     data: null
//   })

//   useEffect(() => {
//     const fetchData = async () => {
//       const result = await httpRequest<ApiResponse>('https://api.example.com/resource', 'GET')
//       setResponse(result)
//     };

//     fetchData()
//   }, [])

//   return (
//     <div>
//       <h1>API Response</h1>
//       {response.status === 'error' ? (
//         <p>Error: {response.data}</p>
//       ) : (
//         <pre>{JSON.stringify(response.data, null, 2)}</pre>
//       )}
//     </div>
//   );
// };

// export default MyComponent;
